/* eslint-disable @typescript-eslint/no-empty-function */
import { useRouter } from 'next/router';
import React, { createContext, useCallback, useContext, useMemo, useState, useEffect } from 'react';

import type { Dispatch, PropsWithChildren, SetStateAction } from 'react';
import { CustomEventTypes } from '../../constants';

export type ViewAsType = 'product' | 'outfit' | 'none';

type ImageSwitchContextType = {
  viewAs: ViewAsType;
  setViewAs: Dispatch<SetStateAction<ViewAsType>>;
};

const ImageSwitchContext = createContext<ImageSwitchContextType>({
  viewAs: 'outfit',
  setViewAs: () => {},
});

export const useImageSwitchContext = () => useContext(ImageSwitchContext);

export const ImageSwitchContextProvider = ({ children }: PropsWithChildren) => {
  const defaultValue = new URLSearchParams(window.location.search).get(
    'imgmode'
  ) as ViewAsType | null;
  const [viewAs, setViewAsFn] = useState<ViewAsType>(defaultValue || 'outfit');

  const router = useRouter();

  const setViewAs: Dispatch<SetStateAction<ViewAsType>> = useCallback(
    value => {
      const url = new URL(window.location.href);

      if (value === 'none') {
        url.searchParams.delete('imgmode');
        url.searchParams.delete('sort');
      } else if (value === 'product') {
        url.searchParams.set('imgmode', value);
      } else {
        url.searchParams.delete('imgmode');
      }

      router.replace(url, url, { shallow: true });

      setViewAsFn(value === 'none' ? 'outfit' : value);
    },
    [router]
  );

  const value = useMemo(() => ({ viewAs, setViewAs }), [viewAs, setViewAs]);

  useEffect(() => {
    const handleImageSwitchContextRequest: () => void = () => {
      document.dispatchEvent(
        new CustomEvent(CustomEventTypes.IMAGE_SWITCH_CONTEXT_RESPONSE, {
          detail: { viewAs, setViewAs },
        })
      );
    };

    document.addEventListener(
      CustomEventTypes.IMAGE_SWITCH_CONTEXT_REQUEST,
      handleImageSwitchContextRequest
    );

    handleImageSwitchContextRequest();

    return () => {
      document.removeEventListener(
        CustomEventTypes.IMAGE_SWITCH_CONTEXT_REQUEST,
        handleImageSwitchContextRequest
      );
    };
  }, [viewAs, setViewAs]);

  return <ImageSwitchContext.Provider value={value}>{children}</ImageSwitchContext.Provider>;
};
