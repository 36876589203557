import { useStaticContext } from '../../../../utilities/context/static/StaticContext';
import { LowestPriceDisplay } from '../../../../utilities/graphql/codegen';
import { S } from './TileTitleWithPrice';

type TileTitleOriginalPriceProps = {
  originalPriceCopy: string | undefined;
  discountedPrice: string | undefined;
  initialPrice: string | undefined;
  discountedColor?: string;
};

export const TileTitleOriginalPrice = ({
  originalPriceCopy,
  discountedPrice,
  initialPrice,
  discountedColor,
}: TileTitleOriginalPriceProps) => {
  const {
    configuration: { lowestPriceDisplay },
  } = useStaticContext();

  const isOnlyLowestPrice = lowestPriceDisplay === LowestPriceDisplay.OnlyLowestPrice;

  return (
    <div data-testid="product-tile-original-price">
      {initialPrice && discountedPrice !== initialPrice ? (
        <S.PriceLowest30Days>
          {isOnlyLowestPrice ? '' : `${originalPriceCopy} ${initialPrice.replace(' ', '')}`}
        </S.PriceLowest30Days>
      ) : (
        <></>
      )}
      <span>
        {discountedPrice && discountedPrice !== initialPrice ? (
          <S.DiscountedPrice
            $isOnlyLowestPrice={isOnlyLowestPrice}
            $color={discountedColor}
            data-testid="product-tile-price"
          >
            {discountedPrice.replace(' ', '')}
          </S.DiscountedPrice>
        ) : (
          <S.InitialPrice data-testid="product-tile-price">{initialPrice}</S.InitialPrice>
        )}
      </span>
    </div>
  );
};
