import { useCallback, useEffect, useRef } from 'react';
import type { MutableRefObject } from 'react';

interface UsePointerAutoscrollProps {
  parentElementRef: MutableRefObject<HTMLElement | null>;
  targetElementRef: MutableRefObject<HTMLElement | null>;
}

export const usePointerAutoscroll = ({
  parentElementRef,
  targetElementRef,
}: UsePointerAutoscrollProps) => {
  const rafID = useRef<ReturnType<typeof requestAnimationFrame>>();

  const clearAnimation = () => {
    if (rafID.current) {
      cancelAnimationFrame(rafID.current);
      rafID.current = undefined;
    }
  };

  const scrollBy = useCallback(
    (amount: number) => {
      const element = targetElementRef.current;
      const { clientWidth = 0, scrollWidth = 0 } = element || {};

      if (clientWidth < scrollWidth) {
        element?.scrollBy({ left: amount });
        rafID.current = requestAnimationFrame(() => scrollBy(amount));
      }
    },
    [targetElementRef]
  );

  useEffect(() => {
    const element = parentElementRef.current;

    const onMouseOver = (e: PointerEvent) => {
      if (e.pointerType === 'touch') {
        return;
      }

      const target = e.currentTarget as HTMLElement;
      const { left, width } = target.getBoundingClientRect();

      const offsetX = e.clientX - left;
      const isLeftEdge = offsetX < width * 0.2;
      const isRightEdge = offsetX > width * 0.8;

      if (isLeftEdge) {
        if (!rafID.current) {
          scrollBy(-1);
        }
      } else if (isRightEdge) {
        if (!rafID.current) {
          scrollBy(1);
        }
      } else {
        clearAnimation();
      }
    };

    const onMouseLeave = () => {
      clearAnimation();
    };

    element?.addEventListener('pointermove', onMouseOver, { passive: true });
    element?.addEventListener('pointerout', onMouseLeave, { passive: true });

    return () => {
      element?.removeEventListener('pointermove', onMouseOver);
      element?.removeEventListener('pointerout', onMouseLeave);
    };
  }, [parentElementRef, scrollBy]);
};
