import styled from 'styled-components';
import type { PropsWithChildren } from 'react';
import { shimmerAnimation } from './styles';
import { colors } from '../core/styles';

const S = {
  Placeholder: styled.div<{ $width: string; $height: string }>`
    background: ${colors.COLOR_GREY_200};
    ${shimmerAnimation};
    width: ${({ $width }) => $width};
    height: ${({ $height }) => $height};
  `,
};

interface SkeletonPlaceholderProps {
  width?: string;
  height?: string;
  className?: string;
}

interface SkeletonProps extends SkeletonPlaceholderProps {
  /**
   * Condition when to show the skeleton
   *
   * If `true` will render the skeleton
   *
   * If `false` will render the children
   */
  when: boolean;
}

export const SkeletonPlaceholder = ({
  width = 'auto',
  height = 'auto',
  className,
}: SkeletonPlaceholderProps) => (
  <S.Placeholder className={className} $height={height} $width={width} />
);

export const Skeleton = ({
  width,
  height,
  className,
  when,
  children,
}: PropsWithChildren<SkeletonProps>) => (
  <>
    {when ? <SkeletonPlaceholder className={className} width={width} height={height} /> : children}
  </>
);
