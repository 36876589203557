import type { ReactElement, MouseEvent } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import dynamic from 'next/dynamic';
import { colors } from '../core/styles';
import type { QuickViewOverlayProps } from './quickViewOverlay/QuickViewOverlay';
import { useStaticContext } from '../../../utilities/context/static/StaticContext';
import type { Product } from '../../../utilities/graphql/codegen';
import { fetchProduct } from '../../../utilities/graphql/queries';
import { useAppContext } from '../../../utilities/context/static/AppContext';
import { generateExponeaViewItemEventProperties } from '../vendors/Exponea';
import { getProductDataLayerEvent, triggerQubitEvent } from '../../../utilities/vendors';
import { useAlgoliaInsights } from '../vendors/useAlgoliaInsights';
import { useDataLayerContext } from '../../../utilities/context/static/DataLayerContext';
import { useProductListContext } from '../../../utilities/context/static/ProductListContext';

const QuickViewOverlay = dynamic<QuickViewOverlayProps>(
  () => import('./quickViewOverlay/QuickViewOverlay').then(mod => mod.QuickViewOverlay),
  { ssr: false }
);

const S = {
  QuickView: styled.div``,
  QuickViewIcon: styled.button`
    display: flex;
    align-items: center;
    order: 1;
    font-size: 30px;
    color: ${colors.BLACK};
    transform: translateY(0);
    aspect-ratio: 1/1;
    position: static;
    width: 42px;
    height: 42px;
    align-self: flex-end;

    &::before {
      content: '\\F182';
      font-family: gstar-icons;
      display: inline-block;
      font-style: normal;
      font-weight: 400;
      line-height: 1em;
      text-decoration: inherit;
      width: 1em;
      margin-right: 0.2em;
      text-align: center;
      vertical-align: middle;
      font-variant: normal;
      text-transform: none;
      margin-left: 0.2em;
    }
  `,
};

interface QuickViewProps {
  code: string;
  pushClickEvent: () => void;
  dataLayerQuickViewProductTileType?: string;
  algoliaClickEventName?: string;
  algoliaAddToCartEventName?: string;
}

export const QuickView = ({
  code,
  pushClickEvent,
  dataLayerQuickViewProductTileType = '',
  algoliaClickEventName = '',
  algoliaAddToCartEventName = '',
}: QuickViewProps): ReactElement => {
  const { locale, pushToContentSquare } = useAppContext();
  const { pushToDataLayer } = useDataLayerContext();
  const {
    accessibilityLabels,
    configuration: { productTileConfiguration, enableExponea, defaultCurrency, enableCookieWall },
  } = useStaticContext();
  const { isPLP } = useProductListContext();
  const { pushToAlgolia } = useAlgoliaInsights();

  const [isOpened, setIsOpened] = useState(false);
  const [fetchedProduct, setFetchedProduct] = useState<Product | undefined>(undefined);

  const preventDefault = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const emitExponeaEvent = (eventProduct: Product) => {
    if (enableExponea && eventProduct) {
      const exponeaViewItemEventProperties = generateExponeaViewItemEventProperties(
        eventProduct,
        defaultCurrency
      );

      pushToDataLayer({
        event: 'exponea_view_item',
        event_name: 'view_item',
        namespace: 'exponea',
        event_properties: exponeaViewItemEventProperties,
      });
    }
  };

  const handleOpenQuickViewOverlay = async () => {
    const productResponse = await fetchProduct(code, locale);

    if (!productResponse || productResponse.errors) {
      // eslint-disable-next-line no-console
      console.log(`product request returned error for ${code}`);
    }

    if (productResponse.product) {
      setFetchedProduct(productResponse.product);
      setIsOpened(true);
      emitExponeaEvent(productResponse.product);
      triggerQubitEvent('ecProduct', productResponse.product, enableCookieWall);
      triggerQubitEvent('ecInteraction', { code: productResponse.product.code }, enableCookieWall);

      const eecProductDetailViewEvent = getProductDataLayerEvent(
        'eecProductDetailView',
        productResponse.product,
        true,
        dataLayerQuickViewProductTileType
      );

      if (eecProductDetailViewEvent) {
        pushToDataLayer(eecProductDetailViewEvent);
      }

      pushClickEvent();
      pushToContentSquare(
        'trackPageview',
        `${window.location.pathname}${window.location.hash.replace('#', '?__')}?cs-popin-quickshop`
      );
      pushToAlgolia(
        isPLP ? 'clickedObjectIDs' : 'convertedObjectIDsAfterSearch',
        algoliaClickEventName,
        productResponse.product.algoliaObjectId
      );
    } else {
      // eslint-disable-next-line no-console
      console.log('product is empty');
    }
  };

  return productTileConfiguration?.showQuickViewOnRecommendations ? (
    <S.QuickView>
      <S.QuickViewIcon
        className="icon"
        onClick={(e: MouseEvent<HTMLElement>) => {
          preventDefault(e);
          handleOpenQuickViewOverlay();
        }}
        data-testid="product-tile-quick-view"
        aria-label={accessibilityLabels?.quickView?.openQuickView}
        aria-haspopup="true"
        aria-expanded={isOpened && !!fetchedProduct}
      />
      {isOpened && fetchedProduct && (
        <QuickViewOverlay
          onClose={() => {
            setIsOpened(false);
            pushToContentSquare(
              'trackPageview',
              `${window.location.pathname}${window.location.hash.replace('#', '?__')}`
            );
          }}
          code={code}
          product={fetchedProduct}
          algoliaAddToCartEventName={algoliaAddToCartEventName}
        />
      )}
    </S.QuickView>
  ) : (
    <></>
  );
};
