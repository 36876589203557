import { useCallback, useEffect, useState } from 'react';
import type { MutableRefObject } from 'react';

const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);

interface UseOverflowIndicatorsProps {
  targetElementRef: MutableRefObject<HTMLElement | null>;
}

export const useOverflowIndicators = ({ targetElementRef }: UseOverflowIndicatorsProps) => {
  const [indicatorsInitialized, setIndicatorsInitialized] = useState(false);

  const calculatePositions = useCallback(
    (target: (EventTarget & Element) | null) => {
      if (!target) {
        return;
      }

      const scrollViewportSize = target.clientWidth ?? 0;
      const scrollContentSize = target.scrollWidth ?? 0;

      // clamps the value to be inside the interval [0, position, scrollContentSize]
      // handles the cases when safari overscrolls for the bounce effect going outside of the interval
      const scrollPosition = Math.abs(
        clamp(Math.ceil(target.scrollLeft) ?? 0, 0, scrollContentSize)
      );
      const scrollStart = 0;
      const scrollEnd = scrollPosition + scrollViewportSize;

      const isStart = scrollPosition > scrollStart;
      const isEnd = scrollContentSize > scrollEnd;

      targetElementRef.current?.setAttribute('data-has-overflow-start', isStart.toString());
      targetElementRef.current?.setAttribute('data-has-overflow-end', isEnd.toString());
    },
    [targetElementRef]
  );

  useEffect(() => {
    const element = targetElementRef.current;
    const boundListener = calculatePositions.bind(null, element);

    window.addEventListener('resize', boundListener, { passive: true });
    element?.addEventListener('scroll', boundListener, { passive: true });

    return () => {
      window.removeEventListener('resize', boundListener);
      element?.removeEventListener('scroll', boundListener);
    };
  }, [calculatePositions, targetElementRef]);

  useEffect(() => {
    if (!indicatorsInitialized && targetElementRef.current) {
      if (targetElementRef.current.scrollWidth > targetElementRef.current.clientWidth) {
        calculatePositions(targetElementRef.current);
        setIndicatorsInitialized(true);
      }
    }

    // should trigger only once after mount when element will have scrollable content
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    indicatorsInitialized,
    targetElementRef.current?.scrollWidth,
    targetElementRef.current?.scrollHeight,
  ]);
};
