import dynamic from 'next/dynamic';
import type { ReactElement } from 'react';
import type { ProductRecommendations as RecommendationsSchema } from '../../../amplienceTypes/schemas/imported/product-recommendations-schema';
import { useAppContext } from '../../../utilities/context/static/AppContext';
import { usePDPContext } from '../../../utilities/context/static/PDPContext';
import type { Recommendations as RecommendationsTypes } from '../../../utilities/graphql/codegen';
import { useRecommendationsQuery } from '../../../utilities/graphql/codegen';
import { CompleteTheLook } from './CompleteTheLook';
import { createQueryParams } from '../../../utilities/parsers';
import { useLazyLoading } from '../../../utilities/hooks';
import { useStaticContext } from '../../../utilities/context/static/StaticContext';

const YouMayAlsoLike = dynamic(() => import('./YouMayAlsoLike'), {
  ssr: false,
});

const RecentlyViewed = dynamic(() => import('./RecentlyViewed'), {
  ssr: false,
});

export interface RecommendationsProp {
  recommendations?: RecommendationsTypes | null;
  ariaRole?: string;
  ariaLabel?: string;
}

export const Recommendations = (props: RecommendationsSchema): ReactElement => {
  const { sectionName } = props;
  const { locale } = useAppContext();
  const {
    product: { code },
  } = usePDPContext();
  const { accessibilityLabels } = useStaticContext();

  const { ref, isIntersecting } = useLazyLoading();

  const { baseSiteId } = createQueryParams(locale);
  const { data } = useRecommendationsQuery({
    variables: {
      baseSiteId,
      code,
      locale,
    },
    ssr: false,
    skip: !code || !isIntersecting,
  });

  const renderComponent = () => {
    switch (sectionName) {
      case 'complete the look':
        return (
          <CompleteTheLook
            {...props}
            ariaLabel={accessibilityLabels?.completeTheLook}
            recommendations={data?.recommendations}
          />
        );
      case 'you may also like':
        return (
          <div role="region" aria-label={accessibilityLabels?.ymal} className="js-qubit-recs">
            <YouMayAlsoLike {...props} recommendations={data?.recommendations} />
          </div>
        );
      case 'recently viewed':
        return (
          <RecentlyViewed
            {...props}
            aria-label={accessibilityLabels?.rv}
            recommendations={data?.recommendations}
          />
        );
      default:
        return <></>;
    }
  };

  return <div ref={ref}>{isIntersecting && renderComponent()}</div>;
};
