import type { ReactElement, ReactNode } from 'react';
import { useMemo } from 'react';
import { S } from './styles';
import type { RecentlyViewedProduct } from '../../../../utilities/helpers';

const ABSOLUTE_POSITIONS = [
  'productSigning--v2--position1',
  'productSigning--v2--position2',
  'productSigning--v2--position3',
  'productSigning--v2--position4',
];
const BOTTOM_RIGHT_POSITIONS = ['productSigning--v2--position6'];

export const TileLegacySignings = ({
  singleProduct,
  signingEnabled,
  quickViewAction,
  className,
}: {
  singleProduct: RecentlyViewedProduct;
  signingEnabled: boolean;
  quickViewAction: ReactNode;
  className?: string;
}): ReactElement => {
  const discountSignings = useMemo(
    () => singleProduct.signings?.filter(signing => signing?.discountSigning),
    [singleProduct.signings]
  );
  const isAbsolutePosition = useMemo(
    () => ABSOLUTE_POSITIONS.some(x => discountSignings?.[0]?.plpPositionClass?.includes(x)),
    [discountSignings]
  );
  const isBottomRight = useMemo(
    () => BOTTOM_RIGHT_POSITIONS.some(x => discountSignings?.[0]?.plpPositionClass?.includes(x)),
    [discountSignings]
  );

  if (!signingEnabled) {
    return <></>;
  }

  return (
    <>
      {isAbsolutePosition &&
        discountSignings?.map(signing => (
          <S.Percent
            key={signing?.label}
            component="span"
            $backgroundColor={signing?.productSigningBackgroundColour}
            $textColor={signing?.productSigningTextColour}
            $position="absolute"
            className={`${signing?.plpPositionClass ?? ''} ${signing?.labelCssClasses ?? ''} ${
              className ?? ''
            }`}
            testId="product-tile-discount-signing"
            data-cs-capture=""
          >
            {signing?.label}
          </S.Percent>
        ))}
      <S.LabelArea>
        <S.PercentArea $rightSide={isBottomRight}>
          {!isAbsolutePosition &&
            discountSignings?.map(signing => (
              <S.Percent
                key={signing?.label}
                component="span"
                $backgroundColor={signing?.productSigningBackgroundColour}
                $textColor={signing?.productSigningTextColour}
                className={`${signing?.plpPositionClass ?? ''} ${signing?.labelCssClasses ?? ''}`}
                testId="product-tile-discount-signing"
                data-cs-capture=""
              >
                {signing?.label}
              </S.Percent>
            ))}

          {quickViewAction}
        </S.PercentArea>
        <div data-testid="product-tile-signings-wrapper">
          {singleProduct.signings
            ?.filter(signing => !signing?.discountSigning && !!signing?.label)
            .map(signing => (
              <S.Label
                key={signing?.label}
                variant="subtitle"
                component="p"
                alignment="center"
                color={signing?.productSigningTextColour || ''}
                $backgroundColor={signing?.productSigningBackgroundColour}
                testId="product-tile-signing"
                data-cs-capture=""
                data-code={signing?.code}
              >
                {signing?.label}
              </S.Label>
            ))}
        </div>
      </S.LabelArea>
    </>
  );
};
