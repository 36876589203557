/**
 * Title translations for fiber ranking badge in the product tiles for accessibility
 */
export const fiberRankingAccessibilityTitles: { [k: string]: string } = {
  en: 'Responsible materials',
  de: 'Nachhaltige Materialen',
  es: 'Materiales responsables',
  fr: 'Matériaux responsables',
  nl: 'Responsible materials',
  ja: 'サステナブル素材',
  ko: '지속 가능한 소재',
};

export const FIBER_RANKING_LANGUAGE = new Set(Object.keys(fiberRankingAccessibilityTitles));
export const FIBER_RANKING_SCORE_THRESHOLD = 2.5;
