import styled from 'styled-components';
import { colors } from '../core/styles';

export const CloseButton = styled.button`
  visibility: hidden;
  padding: 2px 10px;
  width: 40px;
  height: 40px;
  border: 2px solid transparent;

  &:focus-visible {
    border-color: ${colors.NERO_GREY};
  }
`;
