import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import type { ProductPrice } from '../../../../amplienceTypes/schemas/imported/product-price-schema';
import type { RecentlyViewedProduct } from '../../../../utilities/helpers';
import { parseFormattedPrice } from '../../../../utilities/helpers';
import { colors, media } from '../../core/styles';

const S = {
  Wrapper: styled.div`
    color: ${colors.RAVEN_GREY};
    font-size: 12px;
    line-height: 16px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-top: 4px;
    min-height: 16px; /* min-height for 1 lines of lowest 30 days price */

    @media ${media.lessThan('sm')} {
      padding-top: 0;
    }
  `,
};

interface TilePriceLowest30DaysProps {
  product: RecentlyViewedProduct;
  productPrice?: ProductPrice;
  className?: string;
  alwaysDisplayed?: boolean;
  alwaysDiscountsOnly?: boolean;
  onlyLowestPrice?: boolean;
  fromPrice: string;
}

export const TilePriceLowest30Days = ({
  product,
  productPrice,
  className,
  alwaysDisplayed,
  alwaysDiscountsOnly,
  onlyLowestPrice,
  fromPrice,
}: TilePriceLowest30DaysProps) => {
  const { t } = useTranslation('common', { keyPrefix: 'productPrice' });

  const lowest30PriceCopy = productPrice?.lowestPrice30days || t('lowestPrice30days');
  const lowestPrice = product?.formattedLowestPrice ?? '';
  const initialPrice = product.fromPrice?.formattedValue || product.formattedFromPrice || fromPrice;

  const parsedLowestPrice = parseFormattedPrice(lowestPrice);
  const parsedInitialPrice = parseFormattedPrice(initialPrice);

  const isRRPLast30Days = parsedInitialPrice === parsedLowestPrice;

  return (
    <S.Wrapper className={className}>
      {(!isRRPLast30Days || alwaysDisplayed || alwaysDiscountsOnly || onlyLowestPrice) &&
      lowest30PriceCopy &&
      lowestPrice !== '' ? (
        <div data-testid="product-tile-lowest-price">
          {lowest30PriceCopy} {lowestPrice}
        </div>
      ) : (
        <></>
      )}
    </S.Wrapper>
  );
};
