import styled from 'styled-components';
import { Skeleton } from '../common/SkeletonPlaceholder';

const StyleVariantSkeleton = styled(Skeleton)`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-bottom: 4px;
  box-sizing: content-box;
  display: inline-block;

  &:not(:first-child) {
    margin-left: 12px;
  }
`;

const ALWAYS = true;

export const VariantAreaSkeleton = () => (
  <>
    <StyleVariantSkeleton when={ALWAYS} />
    <StyleVariantSkeleton when={ALWAYS} />
    <StyleVariantSkeleton when={ALWAYS} />
  </>
);
