import type { ComponentType, ElementType } from 'react';
import styled from 'styled-components';
import { useAppContext } from '../../../../utilities/context/static/AppContext';
import {
  FIBER_RANKING_LANGUAGE,
  fiberRankingAccessibilityTitles,
} from '../../../../utilities/constants/fiberRanking';

const S = {
  TileFiberRanking: styled.span<{ $language: string }>`
    display: block;
    width: 50px;
    height: 50px;
    background: ${({ $language }) =>
      `url('/_fes/${process.env.BUILD_TIME}/img/svg/fiber-ranking-badge-${$language}.svg')`};
  `,
};

export const TileFiberRanking = ({ wrapper }: { wrapper: ComponentType }) => {
  const { locale } = useAppContext();
  const [language] = locale.split('_');

  const Wrapper: ElementType = wrapper ?? <></>;
  const fiberRankingLang = FIBER_RANKING_LANGUAGE.has(language) ? language : 'en';
  const fiberRankingAccessibilityTitle = fiberRankingAccessibilityTitles[fiberRankingLang] || '';

  return (
    <Wrapper>
      <S.TileFiberRanking $language={fiberRankingLang} title={fiberRankingAccessibilityTitle} />
    </Wrapper>
  );
};
