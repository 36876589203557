import { createContext, useState, useEffect, useMemo, useContext } from 'react';
import type { ReactNode } from 'react';

export enum QubitCustomEvents {
  TK20_EXPERIENCE_OPTIONS_REQUEST = 'TK20-experience-options-request',
  TK20_EXPERIENCE_OPTIONS_RESPONSE = 'TK20-experience-options-response',
  TK22_1_EXPERIENCE_OPTIONS_REQUEST = 'TK22.1-experience-options-request',
  TK22_1_EXPERIENCE_OPTIONS_RESPONSE = 'TK22.1-experience-options-response',
  TK22_1_SEND_GA_EVENT = 'TK22.1-send-ga-event',
  TK22_1_IS_OUT_OF_STOCK_SIZE_DETECTED = 'TK22.1-is-out-of-stock-size-detected',
  TK33_EXPERIENCE_OPTIONS_REQUEST = 'TK33-experience-options-request',
  TK33_EXPERIENCE_OPTIONS_RESPONSE = 'TK33-experience-options-response',
}

export type ExperienceContextInterface = {
  experienceOptions?: {
    tk20?: {
      experienceId?: string;
      experienceName?: string;
      translations?: {
        title?: string;
        fitAnalyticsLabel?: string;
      };
    };
    tk22_1?: {
      experienceId?: string;
      experienceName?: string;
      translations?: {
        ariaSubmitButton?: string;
        confirmationText?: string;
        newsletterCheckboxText?: string;
        notificationTitle?: string;
        invalidEmailText?: string;
        shopSimilarItemsButtonText?: string;
        selectWaistText?: string;
        selectLengthText?: string;
      };
    };
    tk33?: {
      experienceId?: string;
    };
  };
};

const ExperienceContext = createContext<ExperienceContextInterface>({
  experienceOptions: {},
});

export const useExperienceContext = () => useContext(ExperienceContext);

export const ExperienceOptionsProvider = ({ children }: { children: ReactNode }) => {
  const [experienceOptions, setExperienceOptions] = useState({});
  const requestEventsArray = useMemo(
    () => [
      QubitCustomEvents.TK20_EXPERIENCE_OPTIONS_REQUEST,
      QubitCustomEvents.TK22_1_EXPERIENCE_OPTIONS_REQUEST,
      QubitCustomEvents.TK33_EXPERIENCE_OPTIONS_REQUEST,
    ],
    []
  );
  const responseEventsArray = useMemo(
    () => [
      QubitCustomEvents.TK20_EXPERIENCE_OPTIONS_RESPONSE,
      QubitCustomEvents.TK22_1_EXPERIENCE_OPTIONS_RESPONSE,
      QubitCustomEvents.TK33_EXPERIENCE_OPTIONS_RESPONSE,
    ],
    []
  );

  useEffect(() => {
    let newOptions = {};
    const updateState = (event: CustomEvent) => {
      const {
        detail: { experienceId, experienceName, translations, variation },
      } = event;

      newOptions = {
        ...newOptions,
        ...experienceOptions,
        [experienceName]: {
          experienceName,
          experienceId,
          translations,
          variation,
        },
      };

      setExperienceOptions(newOptions);
    };

    const handler = (event: Event) => updateState(event as CustomEvent<unknown>);

    responseEventsArray.forEach(event => document.addEventListener(event, handler));

    return () => {
      responseEventsArray.forEach(event => document.removeEventListener(event, handler));
    };
  }, [experienceOptions, responseEventsArray]);

  useEffect(() => {
    requestEventsArray.forEach(event => {
      document.dispatchEvent(new CustomEvent(event));
    });
  }, [requestEventsArray]);

  const experienceContext = useMemo(() => ({ experienceOptions }), [experienceOptions]);

  return (
    <ExperienceContext.Provider value={experienceContext}>{children}</ExperienceContext.Provider>
  );
};
