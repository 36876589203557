import type { MouseEvent, RefObject, SetStateAction } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Tile } from './tile/Tile';
import type {
  RowsOnMobile,
  SectionName,
} from '../../../amplienceTypes/schemas/imported/product-recommendations-schema';
import type { RecentlyViewedProduct } from '../../../utilities/helpers';
import {
  getRecentlyViewedProductsFromLocalStorage,
  storageAvailable,
} from '../../../utilities/helpers';
import { useAppContext } from '../../../utilities/context/static/AppContext';
import type { EventProduct } from '../../../utilities/vendors';
import { parseProductForImpressions, getProductDataLayerEvent } from '../../../utilities/vendors';
import { RECENTLY_VIEWED_PRODUCTS } from '../../../utilities/constants/localStorageKeys';
import type { Product, StyleVariant } from '../../../utilities/graphql/codegen';
import { useStaticContext } from '../../../utilities/context/static/StaticContext';
import { WishlistAction } from '../../pdp/productDetail/productDetailControl/WishlistAction';
import { media } from '../core/styles';
import { CloseButton } from './styles';
import { QuickView } from '../quickView/QuickView';
import CloseIcon from '../core/icons/CloseIcon';
import type { ProductPrice } from '../../../amplienceTypes/schemas/imported/product-price-schema';
import { useDataLayerContext } from '../../../utilities/context/static/DataLayerContext';
import { CustomEventTypes } from '../../../utilities/constants';

const S = {
  Wrapper: styled.div<{
    $index: number;
    $rowsOnMobile: RowsOnMobile;
    $removed: number[];
    $removeClicked: boolean;
  }>`
    padding-left: 8px;
    padding-bottom: 10px;
    transition: ${({ $removeClicked }) =>
      $removeClicked
        ? 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out, visibility 0.3s ease-in-out'
        : 'none'};
    transform: ${({ $index, $removed }) => {
      let count = 0;

      $removed.forEach(rm => $index >= rm && count++);

      return `translateX(-${count * 100}%)`;
    }};
    opacity: ${({ $index, $removed }) => ($removed.includes($index) ? 0 : 1)};
    visibility: ${({ $index, $removed }) => ($removed.includes($index) ? 'hidden' : 'visible')};

    @media ${media.lessThan('sm')} {
      display: ${({ $index, $rowsOnMobile }) => $index > 3 && $rowsOnMobile !== 'One' && 'none'};
    }

    @media ${media.greaterThan('sm')} {
      height: 100%;
      box-sizing: border-box;
      padding-left: 12px;
    }

    @media (prefers-reduced-motion) {
      transition: none;
    }
  `,
};

interface CarouselTileAdapterProps {
  rowsOnMobile: RowsOnMobile;
  signingEnabled: boolean;
  index: number;
  product: RecentlyViewedProduct;
  sectionName: SectionName;
  carouselRef: RefObject<HTMLDivElement>;
  eventProducts: { [k in SectionName]: EventProduct[] };
  eventOccurred: boolean;
  showQuickView: boolean;
  productPrice?: ProductPrice;
  setTileWidth: (tileWidth: SetStateAction<number>) => void;
  setProducts: (products: SetStateAction<Product[]>) => void;
  setLeftHidden: (leftHidden: SetStateAction<boolean>) => void;
  setRightHidden: (rightHidden: SetStateAction<boolean>) => void;
  setEventProducts: (eventProducts: SetStateAction<{ [k in SectionName]: EventProduct[] }>) => void;
}

export const RecommendationsTile = ({
  rowsOnMobile,
  signingEnabled,
  index,
  product,
  sectionName,
  carouselRef,
  eventProducts,
  eventOccurred,
  showQuickView,
  productPrice,
  setTileWidth,
  setProducts,
  setLeftHidden,
  setRightHidden,
  setEventProducts,
}: CarouselTileAdapterProps) => {
  const [removed, setRemoved] = useState<number[]>([]);
  const [removeClicked, setRemoveClicked] = useState(false);
  const productTileRef = useRef<HTMLDivElement | null>(null);
  const variants = (product?.availableStyleVariantsSorted ?? []) as StyleVariant[];

  const { country } = useAppContext();
  const {
    configuration: { defaultCurrency },
    pageType,
  } = useStaticContext();
  const { pushToDataLayer } = useDataLayerContext();

  const pushDataLayerEvent = (p: Product, position: number, viewType: string) => {
    const eecProductClickEvent = getProductDataLayerEvent('eecProductClick', {
      product: p,
      position,
      sectionName,
      viewType,
      pageType,
    });

    if (eecProductClickEvent) {
      pushToDataLayer(eecProductClickEvent);
    }
  };

  const preventDefault = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onLinkClick = (viewType: string) => pushDataLayerEvent(product, index, viewType);

  const handleRemove = (position: number, removedProduct: Product) => {
    const isLocalStorageAvailable = storageAvailable('localStorage');

    setRemoveClicked(true);
    setRemoved(prev => [...prev, position]);

    const localStorageProducts = getRecentlyViewedProductsFromLocalStorage(country);

    if (isLocalStorageAvailable) {
      localStorage.setItem(
        `${country}_${RECENTLY_VIEWED_PRODUCTS}`,
        JSON.stringify(localStorageProducts.filter((p: Product) => p.url !== removedProduct.url))
      );

      const customEvent = new CustomEvent(CustomEventTypes.RECENTLY_VIEWED_PRODUCTS_LIST_UPDATED);

      window.dispatchEvent(customEvent);
    }

    setTimeout(() => {
      setProducts(prev => prev?.filter((_, i) => position !== i));
      setRemoveClicked(false);
      setRemoved(prev => prev.filter(p => p !== position));

      if (carouselRef.current) {
        const { scrollLeft, offsetWidth, scrollWidth } = carouselRef.current;

        setLeftHidden(scrollLeft === 0);
        setRightHidden(scrollLeft + offsetWidth === scrollWidth);
      }
    }, 300);
  };

  const observerCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const parsedEventProduct = parseProductForImpressions(product, index);

          if (
            !eventProducts[sectionName].find(
              p => p.materialNumber === parsedEventProduct.materialNumber
            )
          ) {
            if (eventOccurred) {
              const eecImpressionsEvent = getProductDataLayerEvent('eecImpressions', {
                eventProducts: [parsedEventProduct],
                sectionName,
                currency: defaultCurrency,
                pageType,
              });

              if (eecImpressionsEvent) {
                pushToDataLayer(eecImpressionsEvent);
              }
            } else {
              setEventProducts(prev => ({
                ...prev,
                [sectionName]: [...prev[sectionName], parsedEventProduct],
              }));
            }
          }
        }
      });
    },
    [
      product,
      index,
      eventProducts,
      sectionName,
      eventOccurred,
      defaultCurrency,
      setEventProducts,
      pageType,
      pushToDataLayer,
    ]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, { threshold: 0.85 });
    const currentRef = productTileRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [observerCallback]);

  return (
    <S.Wrapper
      $index={index}
      $rowsOnMobile={rowsOnMobile}
      $removed={removed}
      $removeClicked={removeClicked}
      ref={(e: HTMLDivElement) => {
        setTileWidth(Math.ceil(e?.getBoundingClientRect().width || 0));
        productTileRef.current = e;
      }}
    >
      <Tile
        productPrice={productPrice}
        tileType={sectionName === 'recently viewed' ? 'rvp' : 'recommendation'}
        product={product}
        variants={variants}
        onLinkClick={() => onLinkClick('general view')}
        signingEnabled={signingEnabled}
        showVariantSelector={sectionName === 'complete the look'}
        wishlistAction={
          sectionName === 'recently viewed' ? (
            <CloseButton
              data-testid="carousel-right-close-icon"
              data-cs-capture=""
              onClick={(e: MouseEvent<HTMLElement>) => {
                preventDefault(e);
                handleRemove(index, product);
              }}
            >
              <CloseIcon />
            </CloseButton>
          ) : (
            <WishlistAction
              baseProduct={product.baseProduct}
              code={product.code}
              sizeType={product.sizeInformation?.sizeType}
              onClick={preventDefault}
              wishlistButtonType="QuickView"
            />
          )
        }
        quickView={
          showQuickView ? (
            <QuickView
              code={product.code || product.baseProduct}
              dataLayerQuickViewProductTileType={
                sectionName === 'complete the look' ? 'recommendationshybris' : 'recentlyviewed'
              }
              algoliaClickEventName={
                sectionName === 'complete the look'
                  ? 'Quickview_CTL_clickedObjectIDs'
                  : 'Quickview_RV_clickedObjectIDs'
              }
              algoliaAddToCartEventName={
                sectionName === 'complete the look'
                  ? 'Quickview_CTL_addToBasket_convertedObjectIDs'
                  : 'Quickview_RV_addToBasket_convertedObjectIDs'
              }
              pushClickEvent={() => onLinkClick('quick view')}
            />
          ) : (
            <></>
          )
        }
      />
    </S.Wrapper>
  );
};
